<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
          </div>
          <h4 class="page-title">Modules</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'module-create' }">
                <button v-show="!loading" type="button" class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  v-if="hasPermissions(['module-create'])">
                  <i class="mdi mdi-plus-circle"></i> Add Module
                </button>
              </router-link>
            </div>
            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-show="!loading">
              <table class="table table-striped dt-responsive w-100 mb-3" id="module-datatable" v-show="!loading">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Module Title</th>
                    <th>Module Short Name</th>
                    <th>Module Category</th>
                    <th>Training Method</th>
                    <th>Trainer</th>
                    <th v-if="hasPermissions(['module-edit'])">Action</th>
                  </tr>
                </thead>
                <tbody v-show="!loading">
                  <template v-for="(module, index) in filterModule" :key="index">
                    <tr v-if="module.trainers.length > 0">
                      <td>{{ ++index }}</td>
                      <td>{{ module.name }}</td>
                      <td>{{ module.short_name }}</td>
                      <td>
                        <span v-if="module.category_id == 2">Behavior</span>
                        <span v-if="module.category_id == 3">Functional</span>
                      </td>
                      <td>{{ module.trainning_method }}</td>
                      <td>
                        <span v-for="(trainer, index) in module.trainers" :key="index">{{ trainer.name }}
                          <span v-if="index + 1 < module.trainers.length">, </span>
                        </span>
                      </td>
                      <td v-if="hasPermissions(['module-edit'])">
                        <span>
                          <router-link class="action-icon" :to="{
                            name: 'module-update',
                            params: { id: module.id },
                          }">
                            <i class="mdi mdi-square-edit-outline"></i>
                          </router-link>
                        </span>
                        <!-- <span @click="deleteModule(module.id)">
                          <i class="fas fa-trash-alt mx-1"></i>
                        </span> -->
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      employees: this.$store.getters["odoo/getAllEmployees"],
      modules: [],
      filterModule: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    async getEmployees() {
      // this.loading = true;
      // await axios
      //   .get(`${this.baseUrlHRIS}api/employees`, {
      //     headers: {
      //       "content-type": "text/plain",
      //     },
      //   })
      //   .then((response) => {
      //     this.employees = response.data.data;
      //   })
      //   .catch(() => {
      //     this.toast.error("Not Found Employees!");
      //   });
      if (this.employees.length == 0) {
        this.loading = true;
      } else {
        // await this.getEmployee();
        this.loading = false;
      }
    },
    checklist(empId) {
      let resultArray;
      this.employees.forEach(function (emp) {
        if (emp.emp_id == empId) {
          resultArray = emp;
        }
      });
      return resultArray;
    },
    async getAllModules() {
      await this.getEmployees();
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/modules`)
        .then((response) => {
          this.modules = response.data.data;

          this.filterModule = this.modules.map((item) => {
            var trainerList = item.trainers.map((data) => {
              let employee = this.checklist(data.employee_id);
              let obj = {
                id: employee?.emp_id,
                name: employee?.name,
              };
              return obj;
            });
            let list = {
              id: item.id,
              name: item.name,
              short_name: item.short_name,
              category_id: item.category_id,
              trainning_method: item.trainning_method,
              trainers: trainerList,
            };
            return list;
          });

          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#module-datatable").DataTable();
    },
    // async deleteModule(id) {
    //   axios
    //     .delete(`${this.baseUrl}admin/v1/modules/` + id, {})
    //     .then(() => {
    //       this.toast.success("Successfully Module Deleted!");
    //       this.getAllModules();
    //     })
    //     .catch((error) => {
    //       this.errors = error.response.data.errors;
    //       this.toast.error("Something Went Wrong!");
    //     });
    // },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#module-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllModules();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
